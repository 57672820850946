<template>
  <tr>
    <td class="table-body__td-loading" :colspan="col">
      <v-progress-linear
        indeterminate
        color="green"
        height="5"
        rounded
      ></v-progress-linear>
    </td>
  </tr>
</template>

<script>
export default {
  props: ['col']
} 
</script>